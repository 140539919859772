/** @jsx jsx */
import Box, { jsx } from 'theme-ui';
import Section from '@fify.at/gatsby-theme-base/src/components/Section/Section';
import Icon from '@fify.at/gatsby-theme-base/src/components/Icon/Icon';
import Col from '@fify.at/gatsby-theme-base/src/components/Grid/Col';
import Row from '@fify.at/gatsby-theme-base/src/components/Grid/Row';
import Heading from '@fify.at/gatsby-theme-base/src/components/Heading/Heading';
import Subheading from '@fify.at/gatsby-theme-base/src/components/Subheading/Subheading';
import Divider from '@fify.at/gatsby-theme-base/src/components/Divider/Divider';
import { Divider as DividerStyle } from '@fify.at/gatsby-theme-base/src/components/Divider/Divider.styles';

export default function ComparsionSection({
  title,
  subtitle,
  header,
  text,
  ...props
}) {
  return (
    <Section {...props}>
      <div sx={{ marginBottom: 7 }}>
        <Heading
          sx={{
            color: 'primary',
          }}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        {/*<div*/}
        {/*  sx={{*/}
        {/*    ...DividerStyle,*/}
        {/*    borderColor: 'primary',*/}
        {/*  }}*/}
        {/*/>*/}
        {subtitle && (
          <Subheading
            sx={{
              color: 'primary',
            }}
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        )}
      </div>

      <Section background="#fff">
        <Row>
          <Col xs={12} md={6} sx={{ marginBottom: 5 }}>
            {header &&
              header.map(headerRow => (
                <Row sx={{ marginBottom: 4 }}>
                  <Col>
                    <p sx={{ fontWeight: 'bold' }}>{headerRow.pro}</p>
                  </Col>
                </Row>
              ))}

            <ul className="fa-ul">
              {text &&
                text.map(
                  textRow =>
                    textRow.pro && (
                      <li>
                        <p>
                          <Icon
                            variant="fa-li fad fa-lg"
                            icon="fa-check-double"
                          />{' '}
                          {textRow.pro}
                        </p>
                      </li>
                    )
                )}
            </ul>
          </Col>
          <Col xs={12} md={6}>
            {header &&
              header.map(headerRow => (
                <Row sx={{ marginBottom: 4 }}>
                  <Col>
                    <p sx={{ fontWeight: 'bold' }}>{headerRow.con}</p>
                  </Col>
                </Row>
              ))}

            <ul className="fa-ul">
              {text &&
                text.map(
                  textRow =>
                    textRow.con && (
                      <li>
                        <p>
                          <Icon variant="fa-li fas fa-lg" icon="fa-ban" />
                          {textRow.con}
                        </p>
                      </li>
                    )
                )}
            </ul>
          </Col>
        </Row>
      </Section>
    </Section>
  );
}
