/** @jsx jsx */
import { jsx } from 'theme-ui';
import {
  ControlledForm,
  handleNetlifySubmit,
  SubmitButton,
  TextField,
  TextArea,
} from '@fify.at/gatsby-theme-base/src/components/Form';
import { Row, Col } from '@fify.at/gatsby-theme-base/src/components/Grid/';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import Alert from '@fify.at/gatsby-theme-base/src/components/Alert/Alert';
import DatePickerWithFormik from './DatePickerWithFormik';
import { Field, ErrorMessage } from 'formik';
import Button from '@fify.at/gatsby-theme-base/src/components/Button/Button';

export default function SchedulerForm({ location, ...rest }) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const [isSubmitted, setSubmitted] = React.useState(false);
  if (isSubmitted) {
    return (
      <Alert variant="success">
        Vielen Dank für Ihre Terminanfrage. Einer unserer regionalen
        Immobilienexperten wird in Kürze mit Ihnen Kontakt aufnehmen.
      </Alert>
    );
  }
  return (
    <ControlledForm
      name="termin-buchen"
      initialValues={{
        vorname: '',
        nachname: '',
        telefon: '',
        email: '',
        startDate: '',
        nachricht: '',
      }}
      validationSchema={formSchema}
      onSubmit={(values, actions) =>
        handleNetlifySubmit(values, actions, location.href).then(() =>
          setSubmitted(true)
        )
      }
    >
      <Row>
        <Col>
          <div sx={{ display: 'inline-block', marginBottom: 2 }}>
            Ihr Wunschtermin *
          </div>
          <input name="startDate" hidden />
          <Field
            sx={{ width: '100%' }}
            component={DatePickerWithFormik}
            name="startDate"
            required
          />
          <ErrorMessage name="DatePickerWithFormik">
            {msg => <div style={{ color: 'red' }}>{msg}</div>}
          </ErrorMessage>
        </Col>
      </Row>
      <Row>
        <Col sm={12} lg={6}>
          <TextField label="Vorname" name="vorname" required />
        </Col>
        <Col sm={12} lg={6}>
          <TextField label="Nachname" name="nachname" required />
        </Col>
      </Row>
      <Row>
        <Col sm={12} lg={6}>
          <TextField label="Telefon" name="telefon" required />
        </Col>
        <Col sm={12} lg={6}>
          <TextField label="E-Mail" name="email" />
        </Col>
      </Row>

      <TextArea label="Nachricht" name="nachricht" rows="5" />
      {mounted && (
        <SubmitButton
          onClick={function() {
            if (typeof fbq !== 'undefined') {
              fbq('track', 'Lead');
            }
          }}
        >
          Termin jetzt buchen <i className="fal fa-arrow-right"></i>
        </SubmitButton>
      )}
    </ControlledForm>
  );
}

const formSchema = Yup.object().shape({
  vorname: Yup.string()
    .min(2, 'Bitte mindestens 2 Buchstaben eingeben')
    .required('Dieses Feld ist verpflichtend'),
  nachname: Yup.string()
    .min(2, 'Bitte mindestens 2 Buchstaben eingeben')
    .required('Dieses Feld ist verpflichtend'),
  telefon: Yup.string().required('Bitte eine gültige Telefonnummer eingeben'),
});
