/** @jsx jsx */
import HomePageLayout from '../components/HomePageLayout';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import Blocks_left_right from '../components/raw_templates/Blocks_left_right';
import HeroSectionContainer from '../@fify.at/gatsby-theme-base/components/HeroSection/HeroSectionContainer';
import React from 'react';
import TestimonialLarge from '../components/TestimonialLarge';
import ComparsionSection from '../components/CompartionSection';
import GuidesPlugin from '../components/GuidesPlugin';
import Text_Image from '../components/raw_templates/Text_Image';
import TestimonialsExtendedSection from '../components/TestimonialsExtendedSection';
import Link from '@fify.at/gatsby-theme-base/src/components/Link';

export default function Home({ location, data }) {
  const ratgeberList = data.site.siteMetadata.ratgeberList.slice(0, 2);
  // const partnerInvestor = data.site.siteMetadata.partnerInvestor.slice(0, 2);

  return (
    <HomePageLayout
      location={location}
      lang="de"
      title="Wir kaufen Ihr Haus"
      description="Wir kaufen Ihr Haus. Für Sie einfach und bequem vom Sofa aus!"
      url={location.href}
      image={data.hero.childImageSharp.fluid}
      keywords={data.site.siteMetadata.keywords}
      header={{ attachment: 'default', appearance: 'default', fluid: true }}
    >
      <Link to="/so-funktioniert-es">
        <HeroSectionContainer
          fluid={false}
          orientation="southEast"
          variant="alpha"
          title="Auf Wunsch in nur 48 Stunden zum Kaufanbot für Ihr Haus"
          subtitle="Ohne Maklergebühren | Ohne eigenen Aufwand | 100% kostenfrei"
          image={data.hero}
          // buttons={[
          //   {
          //     to: '/online-immobilienbewertung',
          //     text: 'Online Immobilie bewerten',
          //   },
          //   {
          //     to: '/terminbuchung',
          //     text: 'Bewertungstermin buchen',
          //   },
          // ]}
        />
      </Link>

      <Blocks_left_right images={data} />

      <Text_Image
        background="white"
        sx={{ padding: 0, textAlign: 'center' }}
        image={data.untenehmensvorstellung}
        title="Der <strong>neue</strong>, <strong>einfache Weg</strong> Ihr Haus zu verkaufen"
        text="<p>Mit WirkaufendeinHaus.at war es noch nie so einfach, Ihre Immobilie in nur 48 Stunden zu verkaufen. Einfach und bequem, direkt von Sofa aus - ohne Aufwand, ohne Ärger.</p>
      <p>Wir verbinden Menschen und Immobilien und bieten privaten Kunden eine Plattform für die einfache und schnelle Ankaufsabwicklung Ihrer Immobilie – ganz ohne Risiko und bei voller Transparenz.</p>"
        buttonText='Mehr über das Unternehmen <i class="fal fa-arrow-right"></i>'
        buttonLink="/unternehmen/"
      />

      <TestimonialLarge
        sx={{ padding: 8 }}
        image={data.testimonialLarge}
        text="Ich habe das Objekt vor Jahren von meiner Mutter geerbt und aus sentimentalen Gründen nicht verkaufen wollen. Zeitweise war es billig vermietet, was das Haus aber nicht besser machte! Nach der Bewertung durch WirkaufendeinHaus.at war nach kurzer Bedenkzeit schnell klar, dass ich hier ohne Aufwand schnell verkaufen kann. Das hat für mich genau gepasst. Wenige Tage später waren wir beim Notar."
        name="Robert K."
        rating="5"
        date="16.05.2020"
      />

      <ComparsionSection
        background="muted"
        title="Warum WirKaufenDeinHaus.at <strong>einfach besser</strong> ist"
        header={[
          {
            pro: 'WirKaufenDeinHaus.at',
            con: 'Traditioneller, privater Hausverkauf',
          },
        ]}
        text={[
          {
            pro:
              'Schnelle und einfache Abwicklung –  direkt vom Sofa aus, ganz ohne Aufwand und Ärger!',
            con: 'Aufwendige, eigenhändige Eruierung des Marktwerts',
          },
          {
            pro:
              'Kostenlose Online Immobilienbewertung – mit nur einem Klick zur ersten Einschätzung des Marktwerts',
            con: 'Der gesamte Abwicklungsprozess liegt in Ihren Händen',
          },
          {
            pro:
              'Persönliche Objektanalyse vor Ort durch einen regionalen Experten',
            con:
              'Risiko eines Ausfalls der Käuferfinanzierung: die Bonität des Käufers muss selbst beurteilt werden',
          },
          {
            pro: 'Rechtsverbindliche Angebotslegung in nur 48 Stunden',
            con: 'Hoher Aufwand für Sanierungsmaßnahmen',
          },
          {
            pro: 'Keine Maklerprovisionen und Zusatzkosten',
            con:
              'Fehlendes Netzwerk: bei Detailfragen bleibt Ihnen der Weg zu einem Experten nicht erspart',
          },
          {
            pro: 'Kein Finanzierungsausfallrisiko',
            con:
              'Zeitaufwand für Besichtigungen und Risiko von Immobilientouristen',
          },
          {
            pro: 'Keine zusätzlichen Aufwände oder Sanierungsmaßnahmen',
            con:
              'Eigenständige Vorbereitung sämtlicher Formalitäten und Unterlagen',
          },
          {
            pro:
              'Übernahme sämtlicher Formalitäten, Verträge und Behördengänge',
            con: 'Eigene Abwicklung der Behördenwege und Vertragserstellung',
          },
          {
            pro:
              'Vertragserstellung und Treuhandabwicklung durch einen Notar oder Rechtsanwalt',
            con: '',
          },
          {
            pro: 'Optional: Übersiedlungsservice',
            con: '',
          },
        ]}
      />

      {/*<Section background="white">*/}
      {/*  <div sx={{ marginBottom: 7 }}>*/}
      {/*    <Heading>Partner & Investoren</Heading>*/}
      {/*  </div>*/}
      {/*  <RatgeberList ratgeberItems={partnerInvestor} />*/}
      {/*</Section>*/}

      <GuidesPlugin ratgeberItems={ratgeberList} />
      <TestimonialsExtendedSection
        background="white"
        testimonials={[
          {
            image: data.testimonialExtended_6.childImageSharp,
            text:
              'Da ich aufgrund einer Erkrankung nicht mehr selbst mein Haus fertigstellen konnte, wollte ich mich schnell und unkompliziert davon trennen. Die Immobilienexpertin von WirkaufendeinHaus.at hat mich ausführlich beraten und mich über fehlende Genehmigungen und daraus resultierende, mögliche Probleme aufgeklärt. Wir waren sehr zufrieden mit der guten Betreuung und raschen Abwicklung. Dank WirkaufendeinHaus.at kann ich meiner Lebensgefährtin und mir nun den großen Wunsch erfüllen und in ein neues Leben starten.',
            name: 'Robert H. aus Phyra',
            rating: '5',
            date: '20.10.2020',
          },
          {
            image: data.testimonialExtended_2.childImageSharp,
            text:
              'Nach meiner Scheidung habe ich versucht, mein Elternhaus zu erhalten. Ich rutschte immer mehr in Schulden hinein. Die Makler schwärmten von hohen Preisen und letztendlich nahm ich den Makler mit der höchsten Preiseinschätzung. Ein paar Monate später stand ich kurz vorm Konkurs! WirkaufendeinHaus.at hat mir ein gutes Angebot gemacht, obendrein noch direkt bei der Bank Aufschub erwirkt und alles für mich erledigt. Ich konnte das Haus dann sogar noch eine Zeit mieten, bis ich was anderes gefunden hatte!',
            name: 'Heinz W.',
            rating: 5,
            date: '15.05.2020',
          },
        ]}
      />
    </HomePageLayout>
  );
}

export const pageQuery = graphql`
  {
    hero: file(name: { eq: "hero-sofunktionierts" }) {
      childImageSharp {
        fluid(maxWidth: 2340, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    untenehmensvorstellung: file(name: { eq: "untenehmensvorstellung2" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 700, fit: COVER, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    testimonialLarge: file(name: { eq: "kundenmeinung" }) {
      childImageSharp {
        fluid(maxWidth: 1800, fit: COVER, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    thomas: file(name: { eq: "home_reg_experte" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 851, fit: COVER, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    grunde_1: file(name: { eq: "5grunde_1" }) {
      childImageSharp {
        fluid(maxWidth: 700, fit: COVER, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    grunde_2: file(name: { eq: "5grunde_2" }) {
      childImageSharp {
        fluid(maxWidth: 700, fit: COVER, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    grunde_3: file(name: { eq: "5grunde_3" }) {
      childImageSharp {
        fluid(maxWidth: 700, fit: COVER, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    grunde_4: file(name: { eq: "5grunde_4" }) {
      childImageSharp {
        fluid(maxWidth: 700, fit: COVER, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    grunde_5: file(name: { eq: "5grunde_5" }) {
      childImageSharp {
        fluid(maxWidth: 700, fit: COVER, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    person_1: file(name: { eq: "person_1" }) {
      childImageSharp {
        fluid(maxWidth: 200, fit: COVER, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    person_2: file(name: { eq: "person_2" }) {
      childImageSharp {
        fluid(maxWidth: 200, fit: COVER, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    person_3: file(name: { eq: "person_3" }) {
      childImageSharp {
        fluid(maxWidth: 200, fit: COVER, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    person_4: file(name: { eq: "person_4" }) {
      childImageSharp {
        fluid(maxWidth: 200, fit: COVER, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    testimonialExtended_1: file(name: { eq: "testimonialExtended_1" }) {
      childImageSharp {
        fluid(maxWidth: 700, fit: COVER, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    testimonialExtended_2: file(name: { eq: "testimonialExtended_2" }) {
      childImageSharp {
        fluid(maxWidth: 700, fit: COVER, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    testimonialExtended_3: file(name: { eq: "testimonialExtended_3" }) {
      childImageSharp {
        fluid(maxWidth: 700, fit: COVER, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    testimonialExtended_4: file(name: { eq: "testimonialExtended_4" }) {
      childImageSharp {
        fluid(maxWidth: 700, fit: COVER, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    testimonialExtended_5: file(name: { eq: "testimonialExtended_5" }) {
      childImageSharp {
        fluid(maxWidth: 700, fit: COVER, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    testimonialExtended_6: file(name: { eq: "testimonialExtended_6" }) {
      childImageSharp {
        fluid(maxWidth: 700, fit: COVER, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    client_1: file(name: { eq: "client_1" }) {
      childImageSharp {
        fluid(maxWidth: 700, fit: COVER, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    client_2: file(name: { eq: "client_2" }) {
      childImageSharp {
        fluid(maxWidth: 700, fit: COVER, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    client_3: file(name: { eq: "client_3" }) {
      childImageSharp {
        fluid(maxWidth: 700, fit: COVER, quality: 75) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    person_1: file(name: { eq: "person_1" }) {
      childImageSharp {
        fluid(maxWidth: 200, fit: COVER, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    person_2: file(name: { eq: "person_2" }) {
      childImageSharp {
        fluid(maxWidth: 200, fit: COVER, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    person_3: file(name: { eq: "person_3" }) {
      childImageSharp {
        fluid(maxWidth: 200, fit: COVER, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    person_4: file(name: { eq: "person_4" }) {
      childImageSharp {
        fluid(maxWidth: 200, fit: COVER, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    award: file(name: { eq: "logo" }) {
      childImageSharp {
        fixed(height: 128, width: 96, quality: 75) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    site {
      siteMetadata {
        keywords
        ratgeberList {
          slug
          url
          name
          image {
            childImageSharp {
              fluid(maxWidth: 700, fit: COVER, quality: 75) {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
          description
        }
        partnerInvestor {
          slug
          url
          name
          image {
            childImageSharp {
              fluid(maxWidth: 700, fit: COVER, quality: 75) {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
          description
        }
      }
    }
    ratgeber_img: file(name: { eq: "ratgeber_img" }) {
      childImageSharp {
        fluid(maxWidth: 700, fit: COVER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
