/** @jsx jsx */
import Heading from '@fify.at/gatsby-theme-base/src/components/Heading/Heading';
import Section from '@fify.at/gatsby-theme-base/src/components/Section/Section';
import { jsx } from 'theme-ui';
import Subheading from '@fify.at/gatsby-theme-base/src/components/Subheading/Subheading';
import { Col, Row } from '@fify.at/gatsby-theme-base/src/components/Grid/';
import { Accordion } from 'react-bootstrap';
import SchedulerForm from '../Forms/SchedulerForm';
import Icon from '@fify.at/gatsby-theme-base/src/components/Icon/Icon';
import Link from '@fify.at/gatsby-theme-base/src/components/Link/Link';

export default function Blocks_left_right(props) {
  return (
    <Section container="small" {...props}>
      <div sx={{ marginBottom: 7 }}>
        <Heading>
          <strong>
            Wir kaufen dein Haus In 5 Schritten
            <br />
            Der erfolgreiche Hausverkauf
          </strong>
        </Heading>
        <Subheading>Einfach, schnell, diskret und sicher.</Subheading>
      </div>
      <IntroContent images={props} />
    </Section>
  );
}

function IntroContent({ images }) {
  // console.log(images)
  return (
    <div>
      <Accordion
        defaultActiveKey="1"
        sx={{
          marginBottom: 2,
        }}
      >
        <Accordion.Toggle
          as={'div'}
          eventKey="0"
          sx={{
            backgroundColor: 'muted',
            paddingX: 4,
            paddingY: 2,
            fontSize: [2, '1.5rem'],
            color: 'primary',
            fontWeight: '700',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <i sx={{ marginRight: 4 }} className="fad fa-lg fa-calendar-alt"></i>{' '}
          Schritt 1: Termin vereinbaren{' '}
          <Icon sx={{ marginLeft: 'auto' }} icon="fad fa-chevron-down" />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Row sx={{ padding: 4 }}>
            <Col xs="12" md="12" lg="12" sx={{ marginBottom: '15px' }}>
              <p>
                Sie vereinbaren einen unverbindlichen Besichtigungstermin mit
                einem WirKaufenDeinHaus.at Immobilienprofi.
              </p>
              <SchedulerForm />
              <p sx={{ '&&&': { marginTop: 4 } }}>
                <small>
                  * <Link to="/datenschutzerklaerung/">Datenschutz</Link> ist
                  uns besonders wichtig! Seien Sie versichert, dass wir Ihre
                  Daten stets diskret und sicher verarbeiten.
                </small>
              </p>
            </Col>
            {/*<Col xs="12" md="6" lg="6">*/}
            {/*  <Image {...images.images.grunde_1.childImageSharp} />*/}
            {/*</Col>*/}
          </Row>
        </Accordion.Collapse>
      </Accordion>
      <Accordion
        defaultActiveKey="1"
        sx={{
          marginBottom: 2,
        }}
      >
        <Accordion.Toggle
          as={'div'}
          eventKey="0"
          sx={{
            backgroundColor: 'muted',
            paddingX: 4,
            paddingY: 2,
            fontSize: [2, '1.5rem'],
            color: 'primary',
            fontWeight: '700',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <i sx={{ marginRight: 4 }} className="fad fa-lg fa-search"></i>{' '}
          Schritt 2: Besichtigung vor Ort{' '}
          <Icon sx={{ marginLeft: 'auto' }} icon="fad fa-chevron-down" />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Row sx={{ padding: 4 }}>
            {/*<Col xs="12" md="6" lg="6" sx={{ marginBottom: '15px' }}>*/}
            {/*  <Image {...images.images.grunde_2.childImageSharp} />*/}
            {/*</Col>*/}
            <Col xs="12" md="12" lg="12">
              <p>
                Unser <strong>Besichtigungstermin</strong> ist für Sie natürlich{' '}
                <strong>kostenlos</strong> und wird <strong>diskret</strong>{' '}
                abgewickelt.
              </p>
              <p>
                Dazu beauftragen wir auf unsere Kosten einen regionalen
                Immobilienexperten aus unserem Partnernetzwerk. Dieser wird Ihre
                Immobilie eingehend besichtigen und für uns ein umfangreiches
                Bewertungsgutachten erstellen, auf Basis dessen wir Ihnen{' '}
                <strong>ein verbindliches Kaufangebot stellen</strong>.
              </p>
              <p>
                Dieses Angebot wird Ihnen in einem zweiten Termin vorgelegt und
                ausführlich besprochen. Natürlich erhalten Sie auch Einblick in
                das <strong>Bewertungsgutachten</strong> und können sich dieses
                erklären lassen. So kennen Sie auch den Verkehrswert Ihrer
                Liegenschaft.
              </p>
              <p>
                Nutzen Sie bei dem Besichtigungstermin auch die Möglichkeit,
                sich <strong>ausführlich beraten</strong> zu lassen. Der
                Immobilienexperte fungiert als Vermittler zwischen Ihnen und uns
                und verhandelt entsprechend Ihrer Wünsche und Möglichkeiten.
              </p>
              <p>
                Unser Service ist es, neben der Ermittlung des Verkehrswerts
                Ihres Hauses, alle Fragen rund um Ihre Immobilie zu beantworten
                (Erbschaft, Scheidung, altersgerechtes Wohnen,…) und Ihre
                speziellen Wünsche zu besprechen. Diese Erkenntnisse fließen
                dann in die Angebotserstellung mit ein.
              </p>
            </Col>
          </Row>
        </Accordion.Collapse>
      </Accordion>
      <Accordion
        defaultActiveKey="1"
        sx={{
          marginBottom: 2,
        }}
      >
        <Accordion.Toggle
          as={'div'}
          eventKey="0"
          sx={{
            backgroundColor: 'muted',
            paddingX: 4,
            paddingY: 2,
            fontSize: [2, '1.5rem'],
            color: 'primary',
            fontWeight: '700',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <i sx={{ marginRight: 4 }} className="fad fa-lg fa-coins"></i> Schritt
          3: Kaufangebot in 48 Stunden{' '}
          <Icon sx={{ marginLeft: 'auto' }} icon="fad fa-chevron-down" />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Row sx={{ padding: 4 }}>
            <Col xs="12" md="12" lg="12">
              <p>
                Hohe Fach- und Marktkenntnisse gepaart mit dem normierten und
                gesetzlich vorgeschriebenen Bewertungsverfahren erlauben uns,
                Ihnen innerhalb von 48 Stunden nach Besichtigung der Immobilie{' '}
                <strong>ein rechtsverbindliches, kostenloses Kaufanbot</strong>{' '}
                – vorbehaltlich aller benötigten Unterlagen - zu legen.
              </p>
              <p>
                Das Kaufanbot präsentieren wir Ihnen in einem 2. Termin und
                besprechen dieses genau mit Ihnen durch.
              </p>
            </Col>
            {/*<Col xs="12" md="6" lg="6" sx={{ marginBottom: '15px' }}>*/}
            {/*  <Image {...images.images.grunde_3.childImageSharp} />*/}
            {/*</Col>*/}
          </Row>
        </Accordion.Collapse>
      </Accordion>
      <Accordion
        defaultActiveKey="1"
        sx={{
          marginBottom: 2,
        }}
      >
        <Accordion.Toggle
          as={'div'}
          eventKey="0"
          sx={{
            backgroundColor: 'muted',
            paddingX: 4,
            paddingY: 2,
            fontSize: [2, '1.5rem'],
            color: 'primary',
            fontWeight: '700',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <i sx={{ marginRight: 4 }} className="fad fa-lg fa-check-double"></i>{' '}
          Schritt 4: Angebotsannahme{' '}
          <Icon sx={{ marginLeft: 'auto' }} icon="fad fa-chevron-down" />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Row sx={{ padding: 4 }}>
            {/*<Col xs="12" md="6" lg="6" sx={{ marginBottom: '15px' }}>*/}
            {/*  <Image {...images.images.grunde_4.childImageSharp} />*/}
            {/*</Col>*/}
            {/*<Col xs="12" md="6" lg="6">*/}
            <Col>
              <p>
                Entscheiden Sie in Ruhe, ob Sie unser Angebot annehmen möchten
                oder nicht.
              </p>

              <p>Dazu bieten wir Ihnen 2 Optionen:</p>

              <ol>
                <li sx={{ marginBottom: 3 }}>
                  <strong>Angebotsannahme:</strong>
                  <br /> Wenn Ihnen unser Angebot gefällt und Sie es annehmen,
                  sind wir verpflichtet, Ihr Haus zu kaufen (Kaufgarantie).
                  <br /> Nach der Angebotsannahme beauftragen wir auf unsere
                  Kosten einen Notar oder Rechtsanwalt mit dem Kaufvertrag und
                  der vollständigen, treuhändischen Abwicklung.
                </li>
                <li>
                  <strong>
                    Wir bieten Ihr Haus unseren vorgemerkten Kunden und Partnern
                    zum Verkauf an
                    <br />
                    (* Erfolgshonorar).
                  </strong>
                  <br />
                  <br />
                  <strong>
                    <u>Ihr Vorteil:</u>
                  </strong>{' '}
                  Sie können unser Angebot trotzdem jederzeit noch annehmen,
                  sollte Ihnen dieser Vorgang zu lange dauern oder Sie es sich
                  anders überlegen.
                  <br />
                  <br />
                  <strong>
                    <u>Ihr Nachteil:</u>
                  </strong>{' '}
                  Die Dauer des Verkaufs kann einige Wochen in Anspruch nehmen.
                  Mehrere Besichtigungen sind notwendig.
                  <br />
                  <br />
                  <u>* Erfolgshonorar:</u> bei tatsächlichem Verkauf 3% + UST
                  vom Kaufpreis <br />
                  Erfolgsgarantie: unser Honorar wird durch einen Notar oder
                  Rechtsanwalt erst gemeinsam mit Ihrem Kaufpreis an uns
                  überwiesen, dadurch haben Sie keine Vorabkosten und höchste
                  Sicherheit.
                </li>
              </ol>

              <p>
                Kommen beide Varianten für Sie nicht in Frage, entstehen für Sie{' '}
                <strong>keine Kosten</strong>, Sie haben aber die Möglichkeit
                das erstellte Bewertungsgutachten jederzeit käuflich zu
                erwerben.
              </p>
            </Col>
          </Row>
        </Accordion.Collapse>
      </Accordion>
      <Accordion
        defaultActiveKey="1"
        sx={{
          marginBottom: 2,
        }}
      >
        <Accordion.Toggle
          as={'div'}
          eventKey="0"
          sx={{
            backgroundColor: 'muted',
            paddingX: 4,
            paddingY: 2,
            fontSize: [2, '1.5rem'],
            color: 'primary',
            fontWeight: '700',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <i
            sx={{ marginRight: 4 }}
            className="fad fa-lg fa-file-signature"
          ></i>{' '}
          Schritt 5: Kaufvertrag{' '}
          <Icon sx={{ marginLeft: 'auto' }} icon="fad fa-chevron-down" />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Row sx={{ padding: 4 }}>
            {/*<Col xs="12" md="6" lg="6">*/}
            <Col>
              <p>
                <strong>
                  Die gute Nachricht: bei uns können Sie sich zurücklehnen!
                </strong>
                <br />
                <br />
                Wir übernehmen{' '}
                <strong>
                  die gesamte Treuhandabwicklung über den Notar/Rechtsanwalt
                </strong>{' '}
                für Sie.
                <br />
                <br />
                Unser Team kümmert sich vertraulich und diskret um sämtliche
                Formalitäten, Verträge und Behördengänge.
              </p>
              <p>
                Wir tragen die Kosten für den{' '}
                <strong>
                  Kaufvertrag, die Abwicklung und die Grunderwerbssteuer
                </strong>{' '}
                und kümmern uns um die{' '}
                <strong>Eintragung ins Grundbuch.</strong>
                <br />
                <br />
                Sie tragen lediglich eventuell mögliche Kosten wie zum Beispiel
                Kosten für Löschungen von Grundbucheintragungen wie Kredite oder
                Exekutionen (dies wird üblicherweise von Ihrer Hausbank
                verlangt) sowie eine allfällige Immobilienertragssteuer.
                <br />
                Gerne stellen wir Ihnen dazu einen Steuerberater zur kostenlosen
                Beratung zur Verfügung.
              </p>
              <p>
                <strong>Schneller, sicher und bequemer geht’s nicht!</strong>
              </p>
            </Col>
            {/*<Col xs="12" md="6" lg="6" sx={{ marginBottom: '15px' }}>*/}
            {/*  <Image {...images.images.grunde_5.childImageSharp} />*/}
            {/*</Col>*/}
          </Row>
        </Accordion.Collapse>
      </Accordion>
    </div>
  );
}
