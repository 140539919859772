/** @jsx jsx */
import { jsx } from 'theme-ui';
import Icon from '@fify.at/gatsby-theme-base/src/components/Icon/Icon';
import { RatingsStyles as ratingsStyles } from './Raitings.styles';
import React from 'react'

export default function Ratings({ rating, ...rest }) {
  let maxRating = 5;
  let ratings = [];
  for (let i = 0; i < maxRating; i++){
    if (i < rating)
      ratings.push(<Icon key={i} sx={{ ...ratingsStyles.RatingsWrapper.Rating }} icon="fa-star" variant="fas" />)
    else {
      ratings.push(<Icon key={i} sx={{ ...ratingsStyles.RatingsWrapper.Rating }} icon="fa-star" variant="far" />)
    }
  }
  return (
    <div sx={{ ...ratingsStyles.RatingsWrapper }}>
      {ratings}
    </div>
  );
}
