/** @jsx jsx */
import Heading from '@fify.at/gatsby-theme-base/src/components/Heading/Heading';
import { jsx } from 'theme-ui';
import Section from '@fify.at/gatsby-theme-base/src/components/Section/Section';
import Image from 'gatsby-image';
import Box from '@fify.at/gatsby-theme-base/src/components/Box/Box';
import Flex from '@fify.at/gatsby-theme-base/src/components/Flex/Flex';
import Subheading from '@fify.at/gatsby-theme-base/src/components/Subheading/Subheading';
import Ratings from './Raitings/Raitings';
import Icon from '@fify.at/gatsby-theme-base/src/components/Icon/Icon';
import Divider from '@fify.at/gatsby-theme-base/src/components/Divider/Divider';
import { Divider as DividerStyle } from '@fify.at/gatsby-theme-base/src/components/Divider/Divider.styles';
import Button from '@fify.at/gatsby-theme-base/src/components/Button/Button';

export default function TestimonialsExtendedSection({
  trimmed,
  testimonials,
  ...rest
}) {
  return (
    <Section {...rest}>
      <div sx={{ marginBottom: 7 }}>
        <Heading sx={{ fontWeight: 'heading', textAlign: 'center', color: '' }}>
          Mit <strong>WirKaufenDeinHaus</strong> verkauft.
        </Heading>
        {/*<div*/}
        {/*  sx={{*/}
        {/*    ...DividerStyle,*/}
        {/*    borderColor: '',*/}
        {/*  }}*/}
        {/*/>*/}
        <Subheading sx={{ color: '' }}>
          Und <strong>sehr zufrieden</strong>.
        </Subheading>
      </div>
      {testimonials && (
        <Flex sx={{ flexWrap: 'wrap' }}>
          {testimonials.map(testimonial => (
            <Testimonial
              key={testimonial.name}
              trimmed={trimmed}
              {...testimonial}
              sx={{
                maxWidth: ['100%', null, null, '50%'],
                boxSizing: 'border-box',
                paddingX: 3,
              }}
            />
          ))}
        </Flex>
      )}
      {/*<div sx={{ display: 'flex', marginTop: 5 }}>*/}
      {/*  <Button*/}
      {/*    sx={{ marginX: 'auto' }}*/}
      {/*    to="/haus-verkaufen/kundenmeinungen/"*/}
      {/*    variant="onDark"*/}
      {/*  >*/}
      {/*    Alle Kundenmeinungen <i class="fal fa-arrow-right"></i>*/}
      {/*  </Button>*/}
      {/*</div>*/}
    </Section>
  );
}

function Testimonial({
  trimmed,
  clientImage,
  image,
  text,
  name,
  rating,
  date,
  className,
}) {
  return (
    <Box className={className} sx={{ marginBottom: 5 }}>
      {image && (
        <Box
          sx={{
            marginX: 'auto',
            borderRadius: 0,
            padding: 2,
            width: '100%',
          }}
        >
          <Image
            fluid={image.fluid}
            objectFit="cover"
            objectPosition="50% 50%"
            sx={{
              boxSizing: 'content-box',
              width: '100%',
            }}
          />
        </Box>
      )}
      <Box
        sx={{
          position: 'relative',
          zIndex: 2,
          backgroundColor: 'white',
          paddingX: 4,
          paddingY: 3,
          marginTop: -5,
          marginX: 5,
          boxShadow: '1px 2px 7px rgba(0,0,0,0.2)',
        }}
      >
        <p
          sx={{
            '&&&': { marginBottom: 0, paddingBottom: 0, fontSize: '0.9em' },
          }}
        >
          {date}
        </p>
        <h5 sx={{ marginY: 2, fontWeight: 'bold' }}>{name}</h5>
        <Ratings rating={rating} />
        <p sx={{ fontStyle: 'italic', marginTop: 4, width: '100%' }}>
          {trimmed ? `${text.slice(0, 200)} ...` : text}
        </p>
      </Box>
    </Box>
  );
}
