/** @jsx jsx */
import Heading from '@fify.at/gatsby-theme-base/src/components/Heading/Heading';
import { jsx } from 'theme-ui';
import Section from '@fify.at/gatsby-theme-base/src/components/Section/Section';
import Image from 'gatsby-image';
import Box from '@fify.at/gatsby-theme-base/src/components/Box/Box';
import Flex from '@fify.at/gatsby-theme-base/src/components/Flex/Flex';
import Button from '@fify.at/gatsby-theme-base/src/components/Button';
import Subheading from '@fify.at/gatsby-theme-base/src/components/Subheading/Subheading';
import Ratings from './Raitings/Raitings';

export default function TestimonialLarge({
  image,
  text,
  name,
  position,
  rating,
  date,
  className,
  ...rest
}) {
  return (
    <Section {...rest}>
      <Flex
        sx={{
          alignItems: 'center',
          flexFlow: ['column', null, 'row'],
        }}
      >
        <Flex
          sx={{
            width: ['100%', null, null, '60%', '45%'],
            order: [1, 1, 0, 0, 0],
          }}
        >
          <Box
            sx={{
              padding: [4, null, null, null, 8],
              position: 'relative',
              zIndex: 2,
              backgroundColor: 'muted',
              marginRight: [0, null, null, -8],
            }}
          >
            <span
              sx={{ display: 'block', marginBottom: 5, fontWeight: 'bold' }}
            >
              Kundenmeinung
            </span>
            <h4 sx={{ marginBottom: 1, fontWeight: 'bold' }}>{name}</h4>
            <p sx={{ fontStyle: 'italic', marginBottom: 5 }}>{position}</p>
            <p sx={{ fontStyle: 'italic', marginBottom: 5 }}>{text}</p>
            <Ratings rating={rating} />
            <p>{date}</p>
            <Button to="/haus-verkaufen/kundenmeinungen/" variant="onDark">
              Alle Kundenmeinungen <i class="fal fa-arrow-right"></i>
            </Button>
          </Box>
        </Flex>
        <Box
          sx={{
            width: ['100%', null, null, '40%', '55%'],
            order: [0, 0, 1, 1, 1],
          }}
        >
          {image && <Image {...image.childImageSharp} />}
        </Box>
      </Flex>
    </Section>
  );
}
